import { createTheme, ThemeProvider } from '@mui/material';
import { Inter } from '@next/font/google';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import mixpanel from 'mixpanel-browser';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import '@/styles/global.css';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { MIXPANEL_EVENTS } from '@/analytics/events';
import { trackEvent } from '@/analytics/utils';
import { COLORS } from '@/constants/colors';
import { isProd } from '@/utils/env';

dayjs.extend(utc); // use plugin
const inter = Inter({ subsets: ['latin'] });

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_APP_ID ?? ''));

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.VIEW_PAGE, { page: router.pathname });
  }, [router.pathname]);

  const theme = createTheme({
    palette: {
      primary: {
        main: COLORS.blue.primary,
        light: COLORS.blue.light,
        dark: COLORS.blue.dark
      }
    }
  });

  return (
    <>
      <Head>
        {!isProd() && <meta name="robots" content="noindex, nofollow" />}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
        <link rel="shortcut icon" href="/favicons/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#9ed7f8" />
        <meta name="msapplication-TileColor" content="#9ed7f8" />
        <meta name="theme-color" content="#9ed7f8" />
      </Head>
      <main className={inter.className}>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </main>
    </>
  );
};

export default App;
