import mixpanel from 'mixpanel-browser';

/**
 * A convenience wrapper for logging an event to multiple platforms.
 *
 * @param {string} name An event name
 * @param {object} properties Properties about the event
 */
export function trackEvent(name: string, properties: object) {
  mixpanel.track(name, properties);
}
