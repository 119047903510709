export const COLORS = {
  'bg-body': '#f9fcfe',
  white: 'rgb(255, 255, 255)',
  black: 'rgb(0, 0, 0)',

  brand: {
    yellow: '#fdcb04',
    orange: {
      light: '#f2b740',
      dark: '#e99e4a'
    },
    blue: {
      light: '#7fc0ff',
      primary: '#0075a4',
      dark: '#035177'
    },
    green: {
      light: '#85e4aa',
      primary: '#09bb9f',
      dark: '#0a8a7b'
    }
  },

  grey: {
    darkest: 'rgb(51, 51, 51)',
    darker: 'rgba(51, 51, 51, 0.9)',
    dark: 'rgba(51, 51, 51, 0.8)',
    primary: 'rgba(51, 51, 51, 0.6)',
    light: '#ddd',
    lighter: '#f2f2f2',
    lightest: 'rgb(248, 248, 248)'
  },
  green: {
    darkest: 'rgb(12, 97, 45)',
    darker: 'rgb(22, 120, 60)',
    dark: 'rgb(47, 150, 87)',
    primary: 'rgb(75, 181, 116)',
    light: 'rgb(133, 228, 170)',
    lighter: '#dafcdb',
    lightest: 'rgb(240, 255, 241)'
  },
  yellow: {
    darkest: 'rgb(132, 108, 35)',
    darker: 'rgb(183, 153, 61)',
    dark: 'rgb(219, 184, 77)',
    primary: 'rgb(255, 216, 96)',
    light: 'rgb(255, 225, 134)',
    lighter: 'rgb(255, 238, 187)',
    lightest: 'rgb(255, 252, 235)'
  },
  purple: {
    darkest: 'rgb(62, 24, 170)',
    darker: 'rgb(87, 64, 180)',
    dark: 'rgb(108, 97, 178)',
    primary: 'rgb(136, 123, 208)',
    light: 'rgb(178, 168, 236)',
    lighter: 'rgb(215, 209, 248)',
    lightest: 'rgb(247, 246, 250)'
  },
  blue: {
    darkest: 'rgb(12, 101, 156)',
    darker: 'rgb(18, 127, 195)',
    dark: 'rgb(15, 144, 225)',
    primary: 'rgb(108, 187, 237)',
    light: 'rgb(158, 215, 248)',
    lighter: '#e7f4fc',
    lightest: 'rgb(240, 250, 255)'
  },
  orange: {
    darkest: 'rgb(161, 88, 0)',
    darker: 'rgb(194, 113, 16)',
    dark: 'rgb(227, 136, 27)',
    primary: 'rgb(241, 157, 57)',
    light: 'rgb(255, 190, 113)',
    lighter: 'rgb(255, 218, 174)',
    lightest: 'rgb(255, 244, 232)'
  },
  red: {
    darkest: 'rgb(143, 0, 24)',
    darker: 'rgb(181, 36, 60)',
    dark: 'rgb(210, 86, 107)',
    primary: 'rgb(221, 115, 125)',
    light: 'rgb(246, 148, 157)',
    lighter: 'rgb(255, 181, 188)',
    lightest: 'rgb(255, 227, 230)'
  }
} as const;
